import React from 'react'
import './styles.css'
import classNames from 'classnames'



export const ColumnTextSection = (props) => {
  const wrapperClassname = classNames( 'pt-6 xs-pt-4 pb-6 column-text-section-wrapper xs-center', {
    'block-purple section-shadow' : props.purple, 
  })
  const tagClassname = classNames(`tag-purple`, {
    'white-tag-override' : props.purple
  }) 
  return (
    <div>
      <div className={props.whiteOverride ? `white-override ${wrapperClassname}` : wrapperClassname }>
        <div className="layout-body">
          {props.header && <div className="grid">
            <div className="col-12 column-text-section-center text-center mb-3 xs-mb-1 ">
              <h1>Header goes here.</h1>
            </div>
          </div>}
          <div className="grid">
            <div className="col-4_sm-12_xs-12 xs-center sm-center">
              {props.tag && <div className="text-small mb-0-5 xs-mt-1">
                <span className={tagClassname}>{props.tag}</span>
              </div>}
              <h3 className="sm-center xs-center mb-1-5 xs-mb-1 column-sub-header-padding">{props.subHeader}</h3>
            </div>
            <div className="col-4_sm-6_xs-12 ">
              <pre className="text-medium mb-1 column-text-p-margin">{props.firstColumn}</pre>
            </div>
            <div className="col-4_sm-6_xs-12 ">
              <pre className="text-medium mb-1 column-text-p-margin">{props.secondColumn}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
