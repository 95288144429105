import React from 'react'
import '../components/Styles/gridlex.scss'
import '../components/Styles/global.css' 

import NovaLayout from '../components/NovaLayout'
import { PageHeader } from '../components/PageHeader'
import { ImageTextSection } from '../components/ImageTextSection'
import { CTASection } from '../components/CTASection'
import { ColumnTextSection } from '../components/ColumnTextSection'

import Johnny from "../images/JohnnyHeadshot.jpeg"


const About = () => {
  const ourStory = {
    subHeader: "Designed for the mortgage industry.",
    firstColumn: <div><p>If you are connected to the Canadian mortgage industry, then the chances are you’ve heard of Finmo.</p><p>If not, you’ve certainly come to the right place.</p><p>The digital mortgage system as a service is now streamlining the processes of brokerages and lenders across the country.</p><p>Most importantly, it’s delighting their customers and helping to close more deals.</p><p>Having co-founded Finmo, our CEO Johnny is therefore delighted to be witnessing this transformation.</p></div>,
    secondColumn: <div><p>However this experience allowed him to learn about the challenges and needs of many of these businesses.</p><p>It soon became clear that although Finmo was proving to be a vital part of the tech stack, there was a lot more mortgage businesses should be doing to capture and convert business online.</p><p>That’s why he set out to create Nova,  a partner to growth focused mortgage businesses, to help them embrace leading inbound strategies, optmize systems and crush it online.</p></div>
  }
  return (
    <NovaLayout title="About | Nova Digital Agency"  description="A digital agency designed for growth-driven mortgage businesses">
      <PageHeader prompt="Nova Agency" heading="Our Story" />
      <ColumnTextSection whiteOverride subHeader={ourStory.subHeader}  firstColumn={ourStory.firstColumn} secondColumn={ourStory.secondColumn}  />
      <div className="layout-body">
        <div className="pb-3 xs-pb-1">
          <div className="grid-12-center">
            <div className="hr mb-4 xs-mb-4 mt-4 xs-mt-0-5" />
            <ImageTextSection image={Johnny} header="Join  Our Team" text=<div><p className="mb-1"><strong>Johnny, Founder & CEO</strong></p><p>We're on a mission to build a thriving, fully remote culture and we’re actively looking for passionate people to join us in supercharging our clients businesses. At Team Nova we have a hunger to learn and take measured risks to win big.  We value clear accountabilty and autonomy in our role, to deliver on what we do best.  If you get a buzz from delivering real results through your work and it sounds like we are a fit, we’d love to hear from you over at <a href="mailto:careers@novaagency.io">careers@novaagency.io</a>.</p></div> />
          </div>
        </div>
      </div>
      <CTASection 
        heading="How can we help?"
        prompt="Let's Get Started"
        buttonText="Get Started"
        gtaText="gtm-about-cta-section-get-started"
      />
    </NovaLayout>
  )
}

export default About
